<template>
    <div class="collection">
        <div class="collection__inner">
            <h1 class="collection__heading">Коллекция Duty Free Flowers</h1>
            <div class="collection__top">
                <div 
                    class="item"
                    v-for="item in mainCollection" 
                    :key="item.id">
                    <span class="item__name">{{item.name}}</span>
                        <img 
                            class="item__img" :src="'https://dff24.com:3000/' + item.url"
                            @click="selectCategory(item.name, item.id)" >
                </div>
            </div>
            <div class="collection__bottom">
                <div 
                    class="item" 
                    v-for="item in subMainCollection" 
                    :key="item.id">
                    <span class="item__name">{{item.name}}</span>
                        <img
                            v-if="item.url" 
                            class="item__img" :src="'https://dff24.com:3000/' + item.url"
                            @click="selectCategory(item.name, item.id)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'GET_COLLECTION',
            'SELECT_CATEGORY',
            'GET_MORE_PRODUCTS',
            'RESET_PAGE_NUMBER'
        ]),
        selectCategory(category, id){
            if(this.$route.name != 'Admin-main-page'){
                this.RESET_PAGE_NUMBER()
                this.SELECT_CATEGORY(category)
                this.GET_MORE_PRODUCTS()
                this.$router.push('/catalog')
            }
            this.$emit('collectionAdminClick', id)
        }
    },
    computed: {
        ...mapGetters([
            'COLLECTION'
        ]),
        mainCollection(){
            return this.COLLECTION.slice(0,2)
        },
        subMainCollection(){
            return this.COLLECTION.slice(2, 6)
        }
    },
    mounted() {
        this.GET_COLLECTION()
    },
}
</script>

<style scoped>
    .collection__inner{
        padding-top: 30px;
        border-top: 1px dashed #000;
    }
    .collection__heading{
        font-size: 28px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .collection__top{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        margin-bottom: 35px;
    }
    .item__name{
        display: inline-block;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .item__img{
        width: 100%;
        aspect-ratio: 1 / 1;
        transition: all linear .2s;
    }
    .item__img:hover{
        transform: scale(.95);
        cursor: pointer;
    }
    .collection__bottom{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: baseline;
        column-gap: 15px;
    }
    @media (max-width: 768px) {
        .item__name{
            text-transform: none;
        }
    }
    @media (max-width: 600px){
        .collection__inner{
            padding-top: 25px;
        }
        .collection__heading{
            font-size: 26px;
            margin-bottom: 30px;
        }
        .item__name{
            text-transform: uppercase;
        }
        .collection__top,
        .collection__bottom{
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0;
            row-gap: 40px;
        }
    }
</style>
