<template>
    <div class="footer">
        <div class="footer__inner">
            <div class="footer__contacts">
                <div class="footer__map">
                    <iframe class="footer__map-interactive" src="https://yandex.ru/map-widget/v1/?um=constructor%3A5a7b12a54fdf9850c86a057e83d6ed18d3c36af369a5b5fa13102bf938573277&amp;source=constructor" frameborder="0" alt="Карта"></iframe>
                </div>
                <div class="contacts contacts__inner" :class="{white: this.$route.name=='wedding'}">
                    <span class="contacts__heading">Контакты:</span>
                    <div class="contacts__addresses">
                        <div class="address address_offset">
                            <p class="address__phone address__ico">+7 (999) 067-77-32</p>
                            <p class="address__location address__ico">Комендантский проспект, 67</p>
                            <p class="address__work-hours">Оформление заказов 24/7</p>
                        </div>

                        <!-- Скрытые адреса -->
                        <div class="contacts__hidden" v-if="isAddressOpened">
                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (931) 535-46-21</p>
                                <p class="address__location address__ico">Московский проспект, 200</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (921) 375-23-26</p>
                                <p class="address__location address__ico">Пражская улица, 3</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (921) 931-01-43</p>
                                <p class="address__location address__ico">Богатырский проспект, 49к1</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (921) 953-73-85</p>
                                <p class="address__location address__ico">Московский проспект, 73</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (981) 136-04-09</p>
                                <p class="address__location address__ico">(Оптовый склад) Заставская улица, 22С</p>
                                <p class="address__work-hours">Оформление заказов 09:00-20:00 Пн - ВС</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (921) 955-02-87</p>
                                <p class="address__location address__ico">Караваевская улица, 25к1</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (999) 034-96-06</p>
                                <p class="address__location address__ico">ул. Оптиков, 37 (вход со стороны улицы)</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7 (921) 405-45-63</p>
                                <p class="address__location address__ico">Европейский проспект, 11</p>
                                <p class="address__work-hours">Оформление заказов 10:00-22:00</p>
                            </div>

                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7(989) 335-75-32</p>
                                <p class="address__location address__ico">Ленинский проспект 72 к. 1</p>
                                <p class="address__work-hours">Оформление заказов 24/7</p>
                            </div>
                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7(911) 127-20-00</p>
                                <p class="address__location address__ico">Ланское шоссе 20</p>
                                <p class="address__work-hours">Оформление заказов 9:00-21:00</p>
                            </div> 
                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7(981)909-32-23</p>
                                <p class="address__location address__ico">Дальневосточный проспект 12 к. 2</p>
                                <p class="address__work-hours">Оформление заказов 9:00-22:00</p>
                            </div> 
                            <div class="address address_offset">
                                <p class="address__phone address__ico">+7(991)672-14-03</p>
                                <p class="address__location address__ico">Российский проспект 8</p>
                                <p class="address__work-hours">Оформление заказов 9:00-21:00</p>
                            </div> 
                            
                        </div>
                        <button class="contacts__btn" @click="openAddresses" :class="{rotate:isAddressOpened}"></button>
                    </div>

                    <div class="contacts__wedding wedding">
                        <p class="wedding__phone">Телефон свадебного отдела:</p>
                        <p class="wedding__tel">+7(981) 012-07-77</p>
                    </div>

                    <div class="social">
                        <span class="contacts__heading">Мы в соцсетях:</span>
                        <div class="social__icons" :class="{socialWed: this.$route.name=='wedding'}">
                            <a href="https://vk.com/aromatnymir24" class="social__ico" :class="{hide: this.$route.name=='wedding'}"><img src="../images/icons/vk-ico.svg" alt="Иконка вк"></a>

                            <a 
                                href="https://t.me/Sdff24" 
                                class="social__ico"
                                v-if="this.$route.name=='wedding'"><img src="../images/icons/tg-ico.svg" alt="Иконка телеграм"></a>

                            <a 
                                href="https://t.me/aromatnyimir24" 
                                class="social__ico"
                                v-else><img src="../images/icons/tg-ico.svg" alt="Иконка телеграм"></a>

                            <a 
                                href="https://wa.me/message/36WIXDRR5RHYE1" 
                                class="social__ico"
                                v-if="this.$route.name=='wedding'"><img src="../images/icons/wa-ico.svg" alt="Иконка вацап"></a>

                            <a 
                                href="https://wa.me/79219259885" 
                                class="social__ico"
                                v-else><img src="../images/icons/wa-ico.svg" alt="Иконка вацап"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer__legal">
                <div class="copyright footer__copyright">
                    <p class="copyright__text">&copy; Интернет-магазин «Duty Free Flowers»,
                        доставка цветов. 2022</p>
                    <p class="copyright__agency">Сайт разработан <a href="https://vsegda-digital.ru/" class="copyright__link">Vsegda-digital</a></p>
                </div>
                <div class="legal">
                    <div class="legal__inner">
                        <p class="legal__text legal__text_indiv">ИП: ЕНИНА ЕКАТЕРИНА СЕРГЕЕВНА</p>
                        <p class="legal__text">ИНН: 645391605852</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAddressOpened: false
        }
    },
    methods: {
        openAddresses(){
            this.isAddressOpened = !this.isAddressOpened
        }
    },
}
</script>

<style scoped>
    .footer{
        background-color: #DFD9C4;
        /* background-color: #EFE7E8; */
    }
    .footer__inner{
        padding: 80px 60px 70px 60px;
    }
    .footer__contacts{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    .footer__map{
        min-height: 360px;
        width: 100%;
        margin-right: 15px;
    }
    .footer__map-interactive{
        height: 100%;
        width: 100%;
    }
    .contacts{
        background-color: #FAF2E4;
    }
    .contacts__inner{
        padding: 35px 45px 45px 45px;
        font-size: 16px;
        min-width: 415px;
    }
    .contacts__heading{
        display: inline-block;
        margin-bottom: 20px;
    }
    .address_offset{
        margin-bottom: 40px;
    }
    .address__ico{
        position: relative;
        padding-left: 30px;
    }
    .address__ico::before{
        content: "";
        position: absolute;
        height: 25px;
        width: 25px;
        top: 0;
        left: 0;
    }
    .address__phone::before{
        background: url(../images/icons/phone-ico.svg) no-repeat;
        background-size: contain;
    }
    .address__location::before{
        background: url(../images/icons/location-ico.svg) no-repeat;
        background-size: contain;
    }
    .address__phone{
        margin-bottom: 15px;
    }
    .address__location{
        margin-bottom: 5px;
    }
    .address__work-hours{
        padding-left: 30px;
        font-size: 14px;
    }
    .contacts__addresses{
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        margin-bottom: 35px;
    }
    .contacts__btn{
        position: absolute;
        height: 20px;
        width: 20px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        right: 0;
        bottom: 55px;
        transform: rotate(45deg);
        transition: all linear .2s;
    }
    .contacts__btn:hover{
        cursor: pointer;
    }
    .social__icons{
        display: flex;
    }
    .social__ico:not(.social__ico:last-child){
        max-height: 40px;
        max-width: 40px;
        margin-right: 30px;
    }
    .social__ico{
        transition: all linear .2s;
    }
    .social__ico:hover{
        opacity: .6;
    }
    .rotate{
        transform: rotate(225deg);
    }
    .footer__legal{
        display: flex;
        justify-content: space-between;
    }
    .legal__inner{
        font-size: 12px;
        text-align: right;
    }
    .footer__copyright{
        max-width: 325px;
        margin-right: 25px;
    }
    .copyright__text{
        margin-bottom: 15px;
    }
    .copyright__link{
        color: #000;
    }
    .white{
        background-color: #fff;
    }
    .hide{
        display: none;
    }
    .legal__text_indiv{
        margin-bottom: 15px;
    }
    .contacts__wedding{
        margin-bottom: 35px;
    }
    .wedding__phone{
        margin-bottom: 5px;
    }
    @media (max-width: 992px) {
        .contacts__inner{
            min-width: auto;
            padding:  25px 35px 25px 35px;
        }
        .contacts__btn{
            right: 50%;
            bottom: 13px;
            margin-right: -10px;
        }
        .footer__map{
            min-height: 300px
        }
        .footer__legal{
            flex-direction: column;
        }
        .footer__copyright{
            margin-bottom: 15px;
        }
        .legal__inner{
            text-align: left;
        }
    }
    @media (max-width: 768px){
        .footer__contacts{
            flex-direction: column;
        }
        .footer__map{
            margin-right: 0;
            margin-bottom: 35px;
        }
        .footer__map-interactive{
            height: 300px;
        }
        @media (max-width: 600px){
            .footer__inner{
                padding: 35px 15px 45px;
            }
            .contacts__btn{
                right: 0;
                bottom: 55px;
            }
            .social__icons{
                justify-content: space-between;
            }
            .footer__contacts{
                margin-bottom: 30px;
            }
            .socialWed{
                justify-content: flex-start;
            }
        }
    }
</style>
